import { useCallback } from 'react';
import {
  PropertyAddress,
  AustralianState,
  LandAreaDetails,
  isRuralProperty,
  StateAgreementFormType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from './useAppSelector';

export const useCanAccessRural = () => {
  const agreements = useAppSelector(
    state => state.agencies.agencyInfo.info?.agreements
  );

  const canAccessRural = useCallback(
    (landAreaDetails: LandAreaDetails | null, address: PropertyAddress) => {
      switch (address?.state) {
        case AustralianState[AustralianState.QLD]:
          return true;
        case AustralianState[AustralianState.NSW]:
          return (
            isRuralProperty(landAreaDetails) ||
            agreements?.includes(StateAgreementFormType.NSWRuralAgreement)
          );
        default:
          return false;
      }
    },
    [agreements]
  );

  return canAccessRural;
};

import {
  PropertyAddress,
  PropertyDetails,
  PropertyImageUrls,
  Form,
  FormType,
  PageStatus,
  VendorBuyer,
  AustralianState,
} from '@urbanx/agx-ui-components';
import { AgxApi } from '../../helpers/http';
import { AppDispatch } from '../../store/store';
import { loadFormConfig } from '../../features/form/formReducer';
import { setAndShowErrorToast } from '../../store/config';
import { getLatLongForAddress } from '../../utils/getLatLong';
import { v4 as uuid } from 'uuid';
import { SendFormSubmission } from './Types/types';
import { cleanFullAddress } from 'utils/formatAddress';

export const campaignsApi = (authToken: string) =>
  new AgxApi('campaigns', authToken);

export const beginCampaign = async (
  authToken: string,
  dispatch: AppDispatch,
  propertyAddress: PropertyAddress,
  formType: FormType,
  state: AustralianState,
  isManualAddress: boolean,
  propertyImageUrls?: PropertyImageUrls,
  propertyDetails?: PropertyDetails,
  vendors?: VendorBuyer[]
) => {
  let details = propertyDetails == null ? null : { ...propertyDetails };

  try {
    if (!details?.latitude && !details?.longitude) {
      if (!details) {
        details = {
          propertyId: null,
          propertyType: null,
          parcels: [
            {
              isPrimaryPlan: false,
              lotNumber: null,
              planType: null,
              planNumber: null,
              titleReference: null,
            },
          ],
          numberOfBeds: null,
          numberOfBathrooms: null,
          numberOfCarSpaces: null,
          landAreaDetails: {
            totalLandArea: null,
            totalLandAreaType: null,
            internalArea: null,
          },
          localGovernment: null,
          latitude: null,
          longitude: null,
        };
      }

      const latLong = await getLatLongForAddress(
        cleanFullAddress(propertyAddress)
      );

      if (latLong?.latitude && latLong?.longitude) {
        details.latitude = latLong.latitude;
        details.longitude = latLong.longitude;
      }
    }

    const { data: formConfig, status } = await campaignsApi(
      authToken
    ).post<Form>('BeginCampaign', {
      propertyAddress,
      propertyImageUrls,
      propertyDetails: details,
      isManualAddress,
      formType,
      state,
      vendors,
    });

    if ([200, 204].includes(status) && formConfig?.formId) {
      dispatch(loadFormConfig(formConfig));
      return true;
    } else {
      dispatch(setAndShowErrorToast('Failed to start new campaign'));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setAndShowErrorToast(err.message));
    return err.message;
  }

  return false;
};

export const loadFormCampaign = async (
  authToken: string,
  campaignId: string,
  formId: string
) => {
  try {
    const { data: form, status } = await campaignsApi(authToken).get<Form>(
      'LoadForm',
      {
        campaignId,
        formId,
      }
    );

    if (status === 200) {
      return form;
    }
  } catch (err: any) {
    console.error(err);
  }

  return null;
};

export const saveFormCampaign = async (
  authToken: string,
  campaignId: string,
  formId: string,
  formType: FormType,
  state: AustralianState,
  formData: any | null,
  pageStatuses: PageStatus[] | null
) => {
  try {
    const { status } = await campaignsApi(authToken).post('SaveForm', {
      campaignId: campaignId,
      formId: formId,
      formType: formType,
      state: state,
      formData,
      pageStatuses,
    });

    if (status === 200) {
      return true;
    }
  } catch (err: any) {
    console.error(err);
  }

  return false;
};

export const completeCampaignStep = async (
  authToken: string,
  campaignId: string,
  forcedCompletion: boolean = false,
  formId?: string
) => {
  try {
    const { data } = await campaignsApi(authToken).post<Form>(
      'CompleteCampaignStep',
      {
        campaignId,
        formId,
        forcedCompletion,
      }
    );

    return data;
  } catch (err: any) {
    console.error(err);
  }

  return null;
};

export const beginCampaignForm = async (
  authToken: string,
  campaignId: string,
  formType: FormType,
  state: AustralianState
) => {
  const newFormId = uuid();

  const newFormCreated = await saveFormCampaign(
    authToken,
    campaignId,
    newFormId,
    formType,
    state,
    null,
    null
  );

  return newFormCreated ? newFormId : null;
};

export const sendForm = async (
  authToken: string,
  sendFormSubmission: SendFormSubmission
) => {
  await campaignsApi(authToken).post('SendForm', sendFormSubmission);
};

import { useState, useEffect } from 'react';
import {
  AgxRow,
  AgxColumn,
  AgxLabel,
  AgxSelect,
  Images,
  Option,
  AgxPropertyLandArea,
  LandAreaDetails,
} from '@urbanx/agx-ui-components';
import { createNumberArray } from 'utils/numbers';
import { IconDetails as AttributeDetails } from './types';

interface AttributeProps {
  id: string;
  defaultValue: AttributeDetails;
  onChange: (value: { id: string; value: AttributeDetails }) => void;
}

const PropertyAttributes = ({ id, defaultValue, onChange }: AttributeProps) => {
  const numbers = createNumberArray(0, 20);
  const numberOptions = numbers.map(
    (number): Option => ({
      value: number.toString(),
      label: number.toString(),
    })
  );

  const defaultValueNumberOfBeds = defaultValue?.numberOfBeds ?? 0;
  const defaultValueNumberOfBathrooms = defaultValue?.numberOfBathrooms ?? 0;
  const defaultValueNumberOfCarSpaces = defaultValue?.numberOfCarSpaces ?? 0;

  const [numberOfBeds, setNumberOfBeds] = useState<Option>({
    label: isNaN(defaultValueNumberOfBeds)
      ? ''
      : defaultValueNumberOfBeds.toString(),
    value: isNaN(defaultValueNumberOfBeds)
      ? ''
      : defaultValueNumberOfBeds.toString(),
  });
  const [numberOfBathrooms, setNumberOfBathrooms] = useState<Option>({
    label: isNaN(defaultValueNumberOfBathrooms)
      ? ''
      : defaultValueNumberOfBathrooms.toString(),
    value: isNaN(defaultValueNumberOfBathrooms)
      ? ''
      : defaultValueNumberOfBathrooms.toString(),
  });
  const [numberOfCarSpaces, setNumberOfCarSpaces] = useState<Option>({
    label: isNaN(defaultValueNumberOfCarSpaces)
      ? ''
      : defaultValueNumberOfCarSpaces.toString(),
    value: isNaN(defaultValueNumberOfCarSpaces)
      ? ''
      : defaultValueNumberOfCarSpaces.toString(),
  });
  const [landAreaDetails, setLandAreaDetails] = useState<LandAreaDetails>(
    defaultValue.landAreaDetails
  );

  useEffect(() => {
    onChange({
      id,
      value: {
        numberOfBeds: parseInt(numberOfBeds.value),
        numberOfBathrooms: parseInt(numberOfBathrooms.value),
        numberOfCarSpaces: parseInt(numberOfCarSpaces.value),
        landAreaDetails: landAreaDetails,
      },
    });
  }, [numberOfBeds, numberOfBathrooms, numberOfCarSpaces, landAreaDetails]);

  return (
    <AgxColumn veryLargeGap>
      <AgxLabel large>Property Attributes</AgxLabel>
      <AgxColumn mediumGap>
        <AgxRow largeGap>
          <AgxSelect
            id="Bed"
            label="Bed"
            options={numberOptions}
            icon={<Images.Bed />}
            onValueChanged={({ value }) =>
              setNumberOfBeds({ label: value, value: value })
            }
            defaultValue={numberOfBeds}
            hideOptionalLabel
          />
          <AgxSelect
            id="Bath"
            label="Bath"
            options={numberOptions}
            icon={<Images.Bath />}
            onValueChanged={({ value }) =>
              setNumberOfBathrooms({ label: value, value: value })
            }
            defaultValue={numberOfBathrooms}
            hideOptionalLabel
          />
          <AgxSelect
            id="Car"
            label="Car"
            options={numberOptions}
            icon={<Images.Car />}
            onValueChanged={({ value }) =>
              setNumberOfCarSpaces({ label: value, value: value })
            }
            defaultValue={numberOfCarSpaces}
            hideOptionalLabel
          />
        </AgxRow>
        <AgxPropertyLandArea
          id="LandArea"
          onChange={({ value }) => setLandAreaDetails(value)}
          landAreaDetails={landAreaDetails}
          validate
        />
      </AgxColumn>
    </AgxColumn>
  );
};

export default PropertyAttributes;

import { useEffect, useState } from 'react';
import {
  AgxCheckbox,
  AgxColumn,
  AgxCurrency,
  AgxTextInput,
  DisplayPriceModel,
  isRuralProperty,
} from '@urbanx/agx-ui-components';
import { SaleType } from '../SaleDetails/SaleDetails';
import './DisplayPrice.scss';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import { useAppSelector } from 'hooks/useAppSelector';

const toBeConfirmedLabel = 'To be confirmed';

interface DisplayPriceProps {
  id: string;
  label: string;
  subLabel?: string | null;
  nonRuralAgreementSubLabel?: string | null;
  subDescription?: string | null;
  onValueChanged: (value: {
    id: string;
    value: DisplayPriceModel | null;
  }) => void;
  defaultValue: DisplayPriceModel | null;
  showToBeConfirmed: boolean | null;
  showReservePrice: boolean | null;
  validate: boolean;
  required?: boolean;
}

const DisplayPrice = ({
  id,
  label,
  subLabel,
  nonRuralAgreementSubLabel,
  subDescription,
  onValueChanged,
  defaultValue = null,
  showToBeConfirmed = false,
  showReservePrice = false,
  validate,
  required = false,
}: DisplayPriceProps) => {
  const propertyDetails = useFormPropertyDetailsSelector();

  const saleDetails = useAppSelector(
    state => state.form?.formValues?.SaleDetails
  );

  const isRural = isRuralProperty(propertyDetails.landAreaDetails);

  const [toBeConfirmed, setToBeConfirmed] = useState(
    defaultValue?.toBeConfirmed ?? false
  );
  const [displayPrice, setDisplayPrice] = useState(defaultValue?.price ?? null);

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        toBeConfirmed,
        price: displayPrice,
      },
    });
  }, [toBeConfirmed, displayPrice]);

  const onTextChanged = ({ value }: { value: string | null }) => {
    if (!toBeConfirmed) {
      setDisplayPrice(value);
    }
  };

  const onCheckboxChanged = ({ value }: { value: boolean }) => {
    setToBeConfirmed(value);
  };

  return (
    <AgxColumn fill largeGap>
      <AgxColumn fill mediumGap>
        {!toBeConfirmed &&
        saleDetails?.saleType === SaleType.Auction &&
        showReservePrice ? (
          <AgxCurrency
            id={id}
            label="Reserve Price"
            onInputValueChange={onTextChanged}
            stretch
            disabled={toBeConfirmed}
            required={required}
            defaultValue={
              toBeConfirmed ? toBeConfirmedLabel : (displayPrice ?? undefined)
            }
            subDescription={subDescription}
            validate={validate}
            noOptionalLabel
            displayErrors={validate && required}
            errorMessage={
              validate && required && !displayPrice
                ? 'Enter reserve price'
                : undefined
            }
          />
        ) : (
          <AgxTextInput
            extraClasses={subLabel ? 'extraGapForSubLabel' : undefined}
            id={id}
            label={
              (isRural &&
                saleDetails?.saleType === SaleType.PrivateTreaty &&
                label) ||
              (isRural &&
                saleDetails?.saleType === SaleType.Auction &&
                'Reserve Price') ||
              (!isRural &&
                saleDetails?.saleType === SaleType.PrivateTreaty &&
                label) ||
              (!isRural &&
                toBeConfirmed &&
                saleDetails?.saleType === SaleType.Auction &&
                'Reserve Price') ||
              (!isRural &&
                saleDetails?.saleType === SaleType.Auction &&
                'Price Guide') ||
              label
            }
            onInputValueChange={onTextChanged}
            stretch
            disabled={toBeConfirmed}
            noOptionalLabel
            required={required}
            defaultValue={
              toBeConfirmed ? toBeConfirmedLabel : (displayPrice ?? undefined)
            }
            subDescription={subDescription ?? undefined}
            subLabel={
              !isRural
                ? (nonRuralAgreementSubLabel ?? undefined)
                : (subLabel ?? undefined)
            }
            parentControlValue
            error={
              validate && required && !displayPrice ? 'Enter display price' : ''
            }
          />
        )}
        {showToBeConfirmed && (
          <AgxCheckbox
            id={`${id}Chkbox`}
            onValueChanged={onCheckboxChanged}
            subLabel={`"${toBeConfirmedLabel}"`}
            stretch
            naked
            defaultValue={toBeConfirmed}
          />
        )}
      </AgxColumn>
    </AgxColumn>
  );
};

export default DisplayPrice;

import { useEffect, useState } from 'react';
import {
  AgxCurrency,
  AgxAutoCompleteAddress,
  AgxColumn,
  AgxLabel,
  AgxCaption,
  AgxColours,
  Images,
  AgxButton,
  AgxRow,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { haversineDistance } from 'utils/getDistance';
import { LatLong, getLatLongForAddress } from 'utils/getLatLong';
import { ComparativeAddress } from '../ComparativeMarketAnalysis/types';
import './AddManually.scss';
import { cleanFullAddress } from 'utils/formatAddress';

const getDefaultValue: () => ComparativeAddress = () => {
  return {
    PropertyAddress: null,
    SoldPrice: null,
    DistanceInKilometers: null,
  };
};

interface AddManuallyProps {
  id: string;
  onValueChanged: (value: { id: string; value: any }) => void;
  defaultValue: any;
}

const AddManually = ({
  id,
  onValueChanged,
  defaultValue,
}: AddManuallyProps) => {
  const [selectedProperties, setSelectedProperties] = useState(
    defaultValue ?? [getDefaultValue()]
  );

  const selectedForm = useAppSelector(state => state.form.selectedForm);
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const maxComparable = 5;
  const propertyDetails = useFormPropertyDetailsSelector();
  const propertyAddress = useFormAddressSelector();
  const formRegion = availableForms.find(
    form => form.type === selectedForm?.formType
  )?.state;
  const [latLong, setLatLong] = useState<LatLong>({
    latitude: propertyDetails?.latitude ?? null,
    longitude: propertyDetails?.longitude ?? null,
  });

  const loadLatLong = async () => {
    if (propertyDetails?.latitude && propertyDetails?.longitude) {
      setLatLong({
        latitude: propertyDetails.latitude,
        longitude: propertyDetails.longitude,
      });
    } else {
      const newLatLong = await getLatLongForAddress(
        cleanFullAddress(propertyAddress)
      );
      if (newLatLong?.latitude && newLatLong?.longitude) {
        setLatLong(newLatLong);
      }
    }
  };

  useEffect(() => {
    loadLatLong();
  }, []);

  useEffect(() => {
    if (selectedProperties != null && selectedProperties.length > 0) {
      onValueChanged({ id, value: selectedProperties });
    }
  }, [selectedProperties]);

  const handleAddressValues = async (
    {
      latitude,
      longitude,
      formattedAddress,
    }: { latitude: number; longitude: number; formattedAddress?: string },
    index: number
  ) => {
    if (selectedProperties == null) return;

    const propertyDistance = haversineDistance(
      latLong.latitude,
      latLong.longitude,
      latitude,
      longitude
    );

    setSelectedProperties([
      ...selectedProperties.map((property: ComparativeAddress, idx: number) => {
        return {
          ...property,
          PropertyAddress:
            idx === index ? formattedAddress : property.PropertyAddress,
          SoldPrice: property.SoldPrice,
          DistanceInKilometers:
            idx === index ? propertyDistance : property.DistanceInKilometers,
        };
      }),
    ]);
  };

  const handleCurrencyInputValues = (value: number, index: number) => {
    if (selectedProperties == null) return;

    setSelectedProperties([
      ...selectedProperties.map(
        (property: ComparativeAddress, curr: number) => {
          return {
            PropertyAddress: property.PropertyAddress,
            SoldPrice: curr === index ? value : property.SoldPrice,
            DistanceInKilometers: property.DistanceInKilometers,
          };
        }
      ),
    ]);
  };

  const handleAddComparable = () => {
    if (selectedProperties == null) return;

    setSelectedProperties([...selectedProperties, getDefaultValue()]);
  };

  const handleRemoveComparable = (index: number) => {
    if (selectedProperties == null || selectedProperties.length <= 1) return;

    setSelectedProperties([
      ...selectedProperties.filter((_: any, idx: number) => idx !== index),
    ]);
  };

  return (
    <AgxColumn extraClasses="manualComparable" fill>
      {selectedProperties &&
        selectedProperties.map(
          (
            {
              PropertyAddress,
              SoldPrice,
              DistanceInKilometers,
            }: ComparativeAddress,
            idx: number
          ) => (
            <AgxColumn fill smallGap key={idx}>
              <div className="manualComparableHeader">
                <AgxRow>
                  <AgxLabel medium> Comparable {idx + 1}</AgxLabel>
                  {selectedProperties.length > 1 && (
                    <AgxButton
                      naked
                      rightIcon={<Images.Trash />}
                      onClick={() => handleRemoveComparable(idx)}
                    />
                  )}
                </AgxRow>
                {DistanceInKilometers != null && (
                  <AgxCaption
                    colour={AgxColours.NEUTRAL_GREY_600}
                    extraClasses="comparableDistance"
                  >
                    {DistanceInKilometers < 1
                      ? `${(DistanceInKilometers * 1000).toFixed(0)}m away`
                      : `${DistanceInKilometers.toFixed(1)}km away`}
                  </AgxCaption>
                )}
              </div>

              <div className="manualComparableAddress">
                <AgxAutoCompleteAddress
                  key={`manualComparableAddress${idx}-${selectedProperties.length}`}
                  id={`manualComparableAddress${idx}-${selectedProperties.length}`}
                  stretch
                  onAddressSelect={() => {}}
                  onClick={value =>
                    handleAddressValues(
                      {
                        latitude: value?.latitude,
                        longitude: value?.longitude,
                        formattedAddress: value?.formattedAddress,
                      },
                      idx
                    )
                  }
                  stateToSearch={formRegion}
                  defaultValue={{ formattedAddress: PropertyAddress ?? '' }}
                  useLongStreetNameInFormattedAddress
                />

                <AgxCurrency
                  key={`manualComparableSoldPrice${idx}-${selectedProperties.length}`}
                  id={`manualComparableSoldPrice${idx}-${selectedProperties.length}`}
                  onInputValueChange={({ value }) => {
                    const currencyValue = Number(value);
                    if (!Number.isNaN(currencyValue)) {
                      handleCurrencyInputValues(currencyValue, idx);
                    }
                  }}
                  placeholder="Price"
                  defaultValue={
                    SoldPrice !== 0 ? SoldPrice?.toString() : undefined
                  }
                  noHeader
                  stretch
                  extraClasses="soldPrice"
                  required={PropertyAddress != null}
                />
              </div>
            </AgxColumn>
          )
        )}
      {selectedProperties.length < maxComparable && (
        <AgxRow extraClasses="manualComparableButtons">
          <AgxButton
            naked
            medium
            text="Add Comparable"
            rightIcon={<Images.PlusCircleBlack />}
            onClick={handleAddComparable}
            wide={ScreenSize() === Breakpoints.Mobile}
          />
        </AgxRow>
      )}
    </AgxColumn>
  );
};

export default AddManually;
